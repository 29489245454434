import { ListLiveAssetsClient } from '@lib/clients'
import {
  setListLiveAssetsLoading,
  setListLiveAssetsState,
} from '@store/actions/list-live-assets.action'
import { getIsLiveAssetsLoading } from '@store/selectors/list-live-assets.selector'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

async function fetchListLiveAssets() {
  return new ListLiveAssetsClient().list()
}

export function useFetchLiveAssets() {
  const dispatch = useDispatch()

  const isListLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)

  useEffect(() => {
    if (isListLiveAssetsLoading) {
      return
    }

    dispatch(setListLiveAssetsLoading(true))
    fetchListLiveAssets()
      .then((liveAssetsResponse) => {
        dispatch(
          setListLiveAssetsState({
            liveAssetsResponse,
          })
        )
      })
      .finally(() => dispatch(setListLiveAssetsLoading(false)))
  }, [])
}
