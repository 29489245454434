import React from 'react'
import { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import store from '@store/store'
import theme from '@lib/theme/theme'
import { useFetchLaunchDarklyApiKey } from '@components/context/feature-flags'
import Layout from '@components-complex/layouts/layout'
import { PageMeta } from '@lib/engine-types'
import {
  AuthProvider,
  AuthenticationSPARequired,
  AuthenticationSSRRequired,
} from '@features/authentication'
import { HistoryProvider } from 'components/context/HistoryContext'
import { LoadingScreen } from '@components-simple/loading-screen'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'
import { useFetchTenantSettings } from '@lib/zustand/tenant-settings'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { getContext } from '@components-context/feature-flags/get-context'
import SystemHelper from '@lib/helpers/system.helper'

import '../styles/styles.scss'
import '../styles/root.css'

type AppPropsExtended = AppProps & { Component: { pageMeta: PageMeta } }

const LD_PROVIDER_OPTIONS = {
  bootstrap: 'localStorage',
} as const

function Main({ Component }: AppPropsExtended) {
  const { auth0Spa, isInitialized } = useCustomFlags()

  const pageMeta = Component.pageMeta

  const AuthenticationRequired = auth0Spa
    ? AuthenticationSPARequired
    : AuthenticationSSRRequired

  if (!isInitialized) {
    return <LoadingScreen />
  }

  return (
    <AuthProvider>
      <AuthenticationRequired pageMeta={pageMeta}>
        <HistoryProvider>
          <ThemeProvider theme={theme}>
            <Layout pageMeta={pageMeta}>
              <Component />
            </Layout>
          </ThemeProvider>
        </HistoryProvider>
      </AuthenticationRequired>
    </AuthProvider>
  )
}

function App(props: AppPropsExtended) {
  const { isLoading, ldApiKey } = useFetchLaunchDarklyApiKey()
  useFetchTenantSettings()

  if (isLoading || !ldApiKey) {
    return <LoadingScreen />
  }

  return (
    <Provider store={store}>
      <LDProvider
        clientSideID={ldApiKey}
        context={getContext(SystemHelper.getCurrentTenantName())}
        options={LD_PROVIDER_OPTIONS}
      >
        <Main {...props} />
      </LDProvider>
    </Provider>
  )
}

export default App
