abstract class PagePathConstant {
  // re-navigating page
  public static readonly HOME = '/'

  // static site
  public static readonly CONTACT_US = 'https://elastio.com/company/contact/'

  public static readonly ELASTIO_DOCS = 'https://docs.elastio.com/'

  public static readonly NOT_FOUND = '/404'

  // rus go to
  public static readonly RUS_GO_TO = '/rus-go-to'

  // predefined lib url
  public static readonly AUTH0_LOGIN = '/api/auth/login'

  public static readonly AUTH0_GLOBAL_LOGIN = '/api/global/auth-global/login'

  public static readonly AUTH0_GLOBAL_LOGOUT = '/api/global/auth-global/logout'

  // predefined lib url
  public static readonly AUTH0_LOGOUT = '/api/auth/logout'

  public static readonly RELOGIN = '/relogin'

  public static readonly EDIT_ACCESS_TOKEN_ID =
    '/account-and-settings/edit-access-token/[id]'

  public static readonly GENERATED_ACCESS_TOKEN_ID =
    '/account-and-settings/generated-access-token/[id]'

  public static readonly ADD_ACCESS_TOKEN =
    '/account-and-settings/add-access-token'

  public static readonly ACCOUNT_AND_SETTINGS = '/account-and-settings'

  public static readonly ACCOUNT_TOKENS = `${PagePathConstant.ACCOUNT_AND_SETTINGS}?t=2`

  public static readonly NOTIFICATION = `${PagePathConstant.ACCOUNT_AND_SETTINGS}?t=3`

  public static readonly ACTION_ID = '/action/[id]'

  //Dashboard V4
  public static readonly DASHBOARD = '/dashboard'

  public static readonly OVA_DASHBOARD = '/ova-dashboard'

  public static readonly INVENTORY_TABLES = `${PagePathConstant.DASHBOARD}/inventory-tables`

  public static readonly EC2_INVENTORY_TABLE = `${PagePathConstant.DASHBOARD}/inventory-tables?t=0`

  public static readonly EBS_INVENTORY_TABLE = `${PagePathConstant.DASHBOARD}/inventory-tables?t=1`

  public static readonly S3_BUCKET_INVENTORY_TABLE = `${PagePathConstant.DASHBOARD}/inventory-tables?t=2`

  public static readonly EFS_INVENTORY_TABLE = `${PagePathConstant.DASHBOARD}/inventory-tables?t=3`

  public static readonly OVA_SERVER_INVENTORY_TABLE = `${PagePathConstant.DASHBOARD}/inventory-tables?t=4`

  public static readonly GENERIC_HOSTS_INVENTORY_TABLE = `${PagePathConstant.DASHBOARD}/inventory-tables?t=5`

  public static readonly ASSET = '/asset/[id]'

  public static readonly INSTANCE = `${PagePathConstant.DASHBOARD}/instance/`

  public static readonly VOLUME = `${PagePathConstant.DASHBOARD}/volume/[id]`

  public static readonly S3BUCKET = `${PagePathConstant.DASHBOARD}/s3-bucket/[id]`

  public static readonly EFS = `${PagePathConstant.DASHBOARD}/efs/[id]`

  public static readonly GENERIC_HOST_DETAILS = `${PagePathConstant.DASHBOARD}/generic/[id]`

  public static readonly RESTORE_INSTANCE = `${PagePathConstant.DASHBOARD}/restore-instance/[id]`

  public static readonly RESTORE_VOLUME = `${PagePathConstant.DASHBOARD}/restore-volume/[id]`

  public static readonly ASSETS_WITH_THREATS = `${PagePathConstant.DASHBOARD}/assets-threats`

  public static readonly ASSETS_WITH_THREATS_OVA_SERVERS = `${PagePathConstant.ASSETS_WITH_THREATS}?t=4` // Cannot inject ENUM here, dunno why

  public static readonly ASSETS_WITH_VULNERABILITIES = `${PagePathConstant.DASHBOARD}/assets-vulnerabilities`

  public static readonly MOUNT_RECOVERY_POINT = `${PagePathConstant.DASHBOARD}/mount-recovery-point`

  public static readonly MOUNT_EC2_RECOVERY_POINT = `${PagePathConstant.MOUNT_RECOVERY_POINT}/instance`

  public static readonly MOUNT_EBS_RECOVERY_POINT = `${PagePathConstant.MOUNT_RECOVERY_POINT}/volume`

  // Create tenant
  public static readonly CHOOSE_TENANT = '/choose-tenant'

  public static readonly CREATE_TENANT_SUCCESS = '/create-tenant-success'

  public static readonly CREATE_TENANT = '/create-tenant'

  public static readonly CLOUD_CONFIGURE_DEPLOYMENT =
    '/deployment/cloud-configure-deployment'

  public static readonly CLOUD_CONFIGURE_DEPLOYMENT_WITH_ACCOUNT =
    '/deployment/cloud-configure-deployment?id=[id]'

  public static readonly CLOUD_CONFIGURE_UPGRADE =
    '/deployment/cloud-configure-upgrade'

  public static readonly CLOUD_CONFIGURE_UPGRADE_WITH_ACCOUNT =
    '/deployment/cloud-configure-upgrade?id=[id]'

  public static readonly JOBS = '/jobs'

  public static readonly CLOUD_CONNECTOR = '/jobs/cloud-connector'

  public static readonly DEPLOYMENT = '/jobs/deployment'

  public static readonly SYSTEM = '/jobs/system'

  public static readonly FILTERS = '/jobs/filters'

  public static readonly POLICIES_ID = '/policies/[id]'

  public static readonly POLICIES_ADD = '/policies/add'

  public static readonly POLICIES = '/policies'

  public static readonly POLICIES_RETENTION = '/policies?t=1'

  public static readonly POLICIES_RETENTION_EDIT_DEFAULT =
    '/policy-retention-edit'

  public static readonly POLICIES_RETENTION_EDIT_ID =
    '/policy-retention-edit/[id]'

  public static readonly POLICIES_RETENTION_ADD = '/policies-retention/add'

  public static readonly SOURCES = '/sources'

  public static readonly SOURCES_ID = '/sources/[id]'

  public static readonly TENANT_ACCESS_DENIED = '/tenant/access-denied'

  public static readonly VERIFICATION = '/verification'

  public static readonly VERIFICATION_STATUS_ID = '/verification-status/[id]'

  public static readonly VERIFICATION_LOADING = '/verification-loading'

  public static readonly SETTINGS = '/settings'

  public static readonly SETTINGS_MEMBERS = `${PagePathConstant.SETTINGS}?t=1`

  public static readonly SETTINGS_MEMBERS_ADD_USER_MODAL = `${PagePathConstant.SETTINGS}?t=1&modal=open`

  public static readonly SETTINGS_WEBHOOKS = `${PagePathConstant.SETTINGS}?t=3`

  public static readonly E2E_HOST = '/e2e-host'

  public static readonly INTEGRITY_SCAN = '/reports/integrity-scan'

  public static readonly FS_CHECK_SCAN = '/reports/fs-check-scan'

  public static readonly REPORTS_OVA_OPERATIONAL = '/reports/ova-operational'

  public static readonly REPORTS_AWS_EXECUTIVE = '/reports/aws-executive'

  public static readonly REPORTS_OVA_EXECUTIVE = '/reports/ova-executive'

  public static readonly REPORTS_ACCOUNT_SUMMARY = '/reports/account-summary'

  public static readonly REPORTS_OPERATIONAL_SUMMARY =
    '/reports/operational-summary'

  public static readonly REPORTS_FSCHECK_DETAILS = '/reports/fs-check/details'

  public static readonly REPORTS_FSCHECK_DETAILS_PARAMS =
    '/reports/fs-check/details/[...params]'

  public static readonly REPORTS = '/reports'

  public static readonly REPORTS_SCHEDULE_ID = '/reports/schedule/[id]'

  public static readonly REPORT_DETAILS_ID = '/reports/details/[id]'

  public static readonly REPORT_DETAILS_FS_CHECK =
    '/reports/details/fs-check-scan'

  public static readonly REPORT_DETAILS_ISCAN = '/reports/details/iscan'

  public static readonly COMPLIANCE = '/reports/compliance-summary'

  public static readonly All_BACKUP_REPORTS = '/reports/all-backup-reports'

  public static readonly EBS_VULNERABILITIES_REPORTS =
    '/reports/ebs-vulnerabilities-reports'

  public static readonly INSTANCE_VULNERABILITIES_REPORTS =
    '/reports/instance-vulnerabilities-reports'

  public static readonly SNAPSHOT_VULNERABILITIES_REPORTS =
    '/reports/snapshot-vulnerabilities-reports'

  public static readonly EBS_VULNERABILITIES_REPORTS_ID =
    '/reports/ebs-vulnerabilities-reports/[id]'

  public static readonly INSTANCE_VULNERABILITIES_REPORTS_ID =
    '/reports/instance-vulnerabilities-reports/[id]'

  public static readonly SNAPSHOT_VULNERABILITIES_REPORTS_ID =
    '/reports/snapshot-vulnerabilities-reports/[id]'

  public static readonly ONBOARDING = '/onboarding'

  public static readonly PROTECT_ASSETS = '/protect-assets'

  public static readonly SOURCE_VAULTS = '/source-vaults/[id]'

  public static readonly VAULT_DETAILED = '/vault-detailed/[id]'

  public static readonly GLOBAL_CELL_HANDLER = '/global-cell/handler'

  public static readonly FORBIDDEN = '/forbidden'

  public static readonly INIT_ERROR = '/init-error'

  public static readonly GLOBAL_SITE = '/global'

  public static readonly TENANT_CREATION = '/tenant-creation'

  public static readonly WEBHOOK_EDIT_ID = '/webhook-edit/[id]'

  public static readonly AUDIT_LOG = '/audit-log'

  public static readonly BILLING = '/billing'

  // [g.js - put new here]

  // Management View

  public static readonly MANAGEMENT_VIEW = '/management-view'

  public static readonly MANAGEMENT_VIEW_EDIT = '/edit-management-view'

  // Active threats

  public static readonly AWS_ASSETS_ACTIVE_THREATS = '/active-threats/aws'

  public static readonly OVA_ASSETS_ACTIVE_THREATS = '/active-threats/ova'

  // Entropy Trends

  public static readonly ENTROPY_TRENDS = '/entropy-trends'
}

export default PagePathConstant
