import { StrHelper, TimeHelper } from '@lib/helpers'
import { useRouter } from 'next/router'
import { UsersClient } from '@lib/clients'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { PagePathConstant } from '@lib/constants'
import { initializeClients } from '@lib/clients/initialize-clients'
import StoreService from '@lib/services/store.service'
import LocalStorageHelper from '@lib/helpers/local-storage.helper'
import store from '@store/store'

type Dependencies = {
  usersClient: UsersClient
  parseTimeZone?: (timeZone?: string) => string | undefined
}

function defaultParseTimeZone(timeZone?: string): undefined | string {
  if (!timeZone) {
    return
  }
  return StrHelper.base64DecodeObj(timeZone)?.value as string
}

export function useAppInitialization(
  isAuthenticated: boolean,
  dependencies: Dependencies
) {
  const [isInitialized, setIsInitialized] = useState(false)
  const { getIdTokenClaims } = useAuth0()
  const router = useRouter()

  const parseTimeZone = dependencies.parseTimeZone || defaultParseTimeZone

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    const initialize = async () => {
      try {
        const claims = await getIdTokenClaims()

        if (!claims) {
          router.push(PagePathConstant.RELOGIN)
          return
        }

        initializeClients(claims.__raw as string)
        StoreService.init(store.dispatch)

        LocalStorageHelper.saveGlobalCellData({
          expiredAt: claims.exp!,
        })

        const userConfig = await dependencies.usersClient.getUserConfig()
        const parsedTimeZone = parseTimeZone(userConfig.TimeZone?.value)
        TimeHelper.initTimezone(parsedTimeZone)

        setIsInitialized(true)
      } catch (error) {
        console.error('Initialization failed:', error)
        router.push(PagePathConstant.INIT_ERROR)
      }
    }

    initialize()
  }, [isAuthenticated, getIdTokenClaims, router])

  return { isInitialized }
}
